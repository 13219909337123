// Import necessary dependencies from React
import React, { useEffect, useContext } from "react";
// Import the usePlaidLink hook from react-plaid-link
import { usePlaidLink } from "react-plaid-link";
// Import the Button component from plaid-threads
import Button from "plaid-threads/Button";

// Import the Context object for accessing global state
import Context from "../../Context";

// Define the Link functional component
const Link = () => {
  // Use the useContext hook to access values from the Context
  // Destructure the required values from the context
  const { linkToken, isPaymentInitiation, isCraProductsExclusively, dispatch } =
    useContext(Context);

  // Define the onSuccess callback function using useCallback for memoization
  const onSuccess = React.useCallback(
    (public_token: string) => {
      // Define an asynchronous function to exchange the public token for an access token
      const exchangePublicTokenForAccessToken = async () => {
        // Send a POST request to the server to exchange the public token
        const response = await fetch("/api/set_access_token", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: `public_token=${public_token}`,
        });
        // If the response is not OK, update the state with error information
        if (!response.ok) {
          dispatch({
            type: "SET_STATE",
            state: {
              itemId: `no item_id retrieved`,
              accessToken: `no access_token retrieved`,
              isItemAccess: false,
            },
          });
          return;
        }
        // If the response is OK, parse the JSON data
        const data = await response.json();
        // Update the state with the received item_id and access_token
        dispatch({
          type: "SET_STATE",
          state: {
            itemId: data.item_id,
            accessToken: data.access_token,
            isItemAccess: true,
          },
        });
      };

      // Check if it's a payment initiation flow
      if (isPaymentInitiation) {
        // For payment initiation, we don't need to exchange for an access token
        dispatch({ type: "SET_STATE", state: { isItemAccess: false } });
      } else if (isCraProductsExclusively) {
        // When only CRA products are enabled, only user_token is needed
        dispatch({ type: "SET_STATE", state: { isItemAccess: false } });
      } else {
        // For other cases, exchange the public token for an access token
        exchangePublicTokenForAccessToken();
      }

      // Update the state to indicate that the link was successful
      dispatch({ type: "SET_STATE", state: { linkSuccess: true } });
      // Update the URL to remove any query parameters
      window.history.pushState("", "", "/");
    },
    [dispatch, isPaymentInitiation, isCraProductsExclusively]
  );

  // Initialize isOauth flag
  let isOauth = false;
  // Configure the Plaid Link
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!, // Use the non-null assertion operator as we assume linkToken is not null
    onSuccess,
  };

  // Check if the current URL includes an OAuth state ID
  if (window.location.href.includes("?oauth_state_id=")) {
    // If it does, set the receivedRedirectUri in the config
    // TODO: figure out how to delete this ts-ignore
    // @ts-ignore
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  // Use the usePlaidLink hook with our config
  const { open, ready } = usePlaidLink(config);

  // Use an effect to automatically open the link if it's an OAuth flow and the link is ready
  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  // Render a button that opens the Plaid Link when clicked
  return (
    <Button type="button" large onClick={() => open()} disabled={!ready}>
      Launch Link
    </Button>
  );
};

// Set the display name for the component (useful for debugging)
Link.displayName = "Link";

// Export the Link component as the default export
export default Link;

// import React, { useEffect, useContext } from "react";
// import { usePlaidLink } from "react-plaid-link";
// import Button from "plaid-threads/Button";

// import Context from "../../Context";

// const Link = () => {
//   const { linkToken, isPaymentInitiation, isCraProductsExclusively, dispatch } =
//     useContext(Context);

//   const onSuccess = React.useCallback(
//     (public_token: string) => {
//       // If the access_token is needed, send public_token to server
//       const exchangePublicTokenForAccessToken = async () => {
//         const response = await fetch("/api/set_access_token", {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
//           },
//           body: `public_token=${public_token}`,
//         });
//         if (!response.ok) {
//           dispatch({
//             type: "SET_STATE",
//             state: {
//               itemId: `no item_id retrieved`,
//               accessToken: `no access_token retrieved`,
//               isItemAccess: false,
//             },
//           });
//           return;
//         }
//         const data = await response.json();
//         dispatch({
//           type: "SET_STATE",
//           state: {
//             itemId: data.item_id,
//             accessToken: data.access_token,
//             isItemAccess: true,
//           },
//         });
//       };

//       // 'payment_initiation' products do not require the public_token to be exchanged for an access_token.
//       if (isPaymentInitiation) {
//         dispatch({ type: "SET_STATE", state: { isItemAccess: false } });
//       } else if (isCraProductsExclusively) {
//         // When only CRA products are enabled, only user_token is needed. access_token/public_token exchange is not needed.
//         dispatch({ type: "SET_STATE", state: { isItemAccess: false } });
//       } else {
//         exchangePublicTokenForAccessToken();
//       }

//       dispatch({ type: "SET_STATE", state: { linkSuccess: true } });
//       window.history.pushState("", "", "/");
//     },
//     [dispatch, isPaymentInitiation, isCraProductsExclusively]
//   );

//   let isOauth = false;
//   const config: Parameters<typeof usePlaidLink>[0] = {
//     token: linkToken!,
//     onSuccess,
//   };

//   if (window.location.href.includes("?oauth_state_id=")) {
//     // TODO: figure out how to delete this ts-ignore
//     // @ts-ignore
//     config.receivedRedirectUri = window.location.href;
//     isOauth = true;
//   }

//   const { open, ready } = usePlaidLink(config);

//   useEffect(() => {
//     if (isOauth && ready) {
//       open();
//     }
//   }, [ready, open, isOauth]);

//   return (
//     <Button type="button" large onClick={() => open()} disabled={!ready}>
//       Launch Link
//     </Button>
//   );
// };

// Link.displayName = "Link";

// export default Link;
