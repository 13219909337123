// Import React library
import React from "react";

// Import custom types and components
import { DataItem, Categories } from "../../dataUtilities";
import Identity from "./Identity";

// Import styles for this component
import styles from "./index.module.scss";

// Define the props interface for the Table component
interface Props {
  data: Array<DataItem>;      // Array of data items to display in the table
  categories: Array<Categories>; // Array of category objects defining table structure
  isIdentity: boolean;        // Flag to determine if this is an identity table
}

// Define the Table functional component
const Table = (props: Props) => {
  // Set maximum number of rows to display
  const maxRows = 15;

  // Create table headers based on category titles
  const headers = props.categories.map((category, index) => (
    <th key={index} className={styles.headerField}>
      {category.title}
    </th>
  ));

  // Create table rows based on data and categories
  const rows = props.data
    .map((item: DataItem | any, index) => (
      <tr key={index} className={styles.dataRows}>
        {props.categories.map((category: Categories, index) => (
          <td key={index} className={styles.dataField}>
            {item[category.field]}
          </td>
        ))}
      </tr>
    ))
    .slice(0, maxRows); // Limit the number of rows to maxRows

  // Conditional rendering based on isIdentity prop
  return props.isIdentity ? (
    // If isIdentity is true, render the Identity component
    <Identity data={props.data} categories={props.categories} />
  ) : (
    // Otherwise, render a regular table
    <table className={styles.dataTable}>
      <thead className={styles.header}>
        <tr className={styles.headerRow}>{headers}</tr>
      </thead>
      <tbody className={styles.body}>{rows}</tbody>
    </table>
  );
};

// Set the display name for the component (useful for debugging)
Table.displayName = "Table";

// Export the Table component as the default export
export default Table;

// import React from "react";

// import { DataItem, Categories } from "../../dataUtilities";
// import Identity from "./Identity";

// import styles from "./index.module.scss";

// interface Props {
//   data: Array<DataItem>;
//   categories: Array<Categories>;
//   isIdentity: boolean;
// }

// const Table = (props: Props) => {
//   const maxRows = 15;
//   // regular table
//   const headers = props.categories.map((category, index) => (
//     <th key={index} className={styles.headerField}>
//       {category.title}
//     </th>
//   ));

//   const rows = props.data
//     .map((item: DataItem | any, index) => (
//       <tr key={index} className={styles.dataRows}>
//         {props.categories.map((category: Categories, index) => (
//           <td key={index} className={styles.dataField}>
//             {item[category.field]}
//           </td>
//         ))}
//       </tr>
//     ))
//     .slice(0, maxRows);

//   return props.isIdentity ? (
//     <Identity data={props.data} categories={props.categories} />
//   ) : (
//     <table className={styles.dataTable}>
//       <thead className={styles.header}>
//         <tr className={styles.headerRow}>{headers}</tr>
//       </thead>
//       <tbody className={styles.body}>{rows}</tbody>
//     </table>
//   );
// };

// Table.displayName = "Table";

// export default Table;
