// Import React library
import React from "react";

// Import styles for this component
import styles from "./index.module.scss";

// Define the Props interface for TypeContainer component
interface Props {
  children?: React.ReactNode | Array<React.ReactNode>; // Optional prop for child elements
  productType: string; // Required prop for the product type
}

// Define the TypeContainer functional component
// Use React.FC (FunctionComponent) type with Props interface
const TypeContainer: React.FC<Props> = (props) => (
  // Main container div
  <div className={styles.container}>
    {/* Header displaying the product type */}
    <h4 className={styles.header}>{props.productType}</h4>
    {/* Render children components or elements */}
    {props.children}
  </div>
);

// Set the display name for the component (useful for debugging)
TypeContainer.displayName = "TypeContainer";

// Export the TypeContainer component as the default export
export default TypeContainer;

// import React from "react";

// import styles from "./index.module.scss";

// interface Props {
//   children?: React.ReactNode | Array<React.ReactNode>;
//   productType: string;
// }

// const TypeContainer: React.FC<Props> = (props) => (
//   <div className={styles.container}>
//     <h4 className={styles.header}>{props.productType}</h4>
//     {props.children}
//   </div>
// );

// TypeContainer.displayName = "TypeContainer";

// export default TypeContainer;
