// Import React library
import React from "react";

// Import custom components
import Endpoint from "../Endpoint";
import ProductTypesContainer from "./ProductTypesContainer";

// Import utility functions and data structures
import {
  transformItemData,     // Function to transform item data
  transformAccountsData, // Function to transform accounts data
  itemCategories,        // Categories for item data
  accountsCategories,    // Categories for accounts data
} from "../../dataUtilities";

// Define the Items functional component
const Items = () => (
  // React Fragment to group multiple elements without adding extra nodes to the DOM
  <>
    {/* ProductTypesContainer wraps the Endpoints, categorizing them as "Item Management" */}
    <ProductTypesContainer productType="Item Management">
      {/* Endpoint component for retrieving item information */}
      <Endpoint
        endpoint="item"  // Specifies the API endpoint
        categories={itemCategories}  // Passes categories for structuring item data
        schema="/item/get/"  // Specifies the API schema
        description="Retrieve information about an Item, like the institution,
        billed products, available products, and webhook
        information."  // Provides a description of the endpoint's purpose
        transformData={transformItemData}  // Function to transform the raw API data
      />
      {/* Endpoint component for retrieving accounts information */}
      <Endpoint
        endpoint="accounts"  // Specifies the API endpoint
        schema="/accounts/get"  // Specifies the API schema
        categories={accountsCategories}  // Passes categories for structuring accounts data
        description="Retrieve high-level information about all accounts associated with an item."  // Provides a description of the endpoint's purpose
        transformData={transformAccountsData}  // Function to transform the raw API data
      />
    </ProductTypesContainer>
  </>
);

// Set the display name for the component (useful for debugging)
Items.displayName = "Items";

// Export the Items component as the default export
export default Items;

// import React from "react";

// import Endpoint from "../Endpoint";
// import ProductTypesContainer from "./ProductTypesContainer";
// import {
//   transformItemData,
//   transformAccountsData,
//   itemCategories,
//   accountsCategories,
// } from "../../dataUtilities";

// const Items = () => (
//   <>
//     <ProductTypesContainer productType="Item Management">
//       <Endpoint
//         endpoint="item"
//         categories={itemCategories}
//         schema="/item/get/"
//         description="Retrieve information about an Item, like the institution,
//         billed products, available products, and webhook
//         information."
//         transformData={transformItemData}
//       />
//       <Endpoint
//         endpoint="accounts"
//         schema="/accounts/get"
//         categories={accountsCategories}
//         description="Retrieve high-level information about all accounts associated with an item."
//         transformData={transformAccountsData}
//       />
//     </ProductTypesContainer>
//   </>
// );

// Items.displayName = "Items";

// export default Items;
