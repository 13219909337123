// Import React and the useContext hook for accessing context
import React, { useContext } from "react";
// Import UI components from the plaid-threads library
import Callout from "plaid-threads/Callout";
import Button from "plaid-threads/Button";
import InlineLink from "plaid-threads/InlineLink";

// Import custom Link component
import Link from "../Link";
// Import the Context object for accessing global state
import Context from "../../Context";

// Import styles for this component
import styles from "./index.module.scss";

// Define the Header functional component
const Header = () => {
  // Use the useContext hook to access values from the Context
  // Destructure the required values from the context
  const {
    itemId,              // Unique identifier for a financial institution connection
    accessToken,         // Token used to access financial data via Plaid API
    userToken,           // Token associated with a specific user
    linkToken,           // Token used to initialize Plaid Link
    linkSuccess,         // Boolean indicating if Link flow was successful
    isItemAccess,        // Boolean indicating if an Item was successfully accessed
    backend,             // Boolean indicating if the backend is operational
    linkTokenError,      // Object containing error information if Link token creation failed
    isPaymentInitiation, // Boolean indicating if this is a payment initiation flow
  } = useContext(Context);

  // Render the component
  return (
    // Main container for the header
    <div className={styles.grid}>
      {/* Main title of the application */}
      <h3 className={styles.title}>Plaid Quickstart</h3>

      {/* Conditional rendering based on whether Link flow was successful */}
      {!linkSuccess ? (
        // Content to display when Link flow hasn't succeeded yet
        <>
          {/* Subtitle explaining the purpose of the app */}
          <h4 className={styles.subtitle}>
            A sample end-to-end integration with Plaid
          </h4>
          {/* Paragraph explaining the Plaid Link flow */}
          <p className={styles.introPar}>
            The Plaid flow begins when your user wants to connect their bank
            account to your app. Simulate this by clicking the button below to
            launch Link - the client-side component that your users will
            interact with in order to link their accounts to Plaid and allow you
            to access their accounts via the Plaid API.
          </p>
          {/* Conditional rendering based on backend status and link token availability */}
          {!backend ? (
            // Display a warning if the backend is not running
            <Callout warning>
              Unable to fetch link_token: please make sure your backend server
              is running and that your .env file has been configured with your
              <code>PLAID_CLIENT_ID</code> and <code>PLAID_SECRET</code>.
            </Callout>
          ) : linkToken == null && backend ? (
            // Display a warning if the backend is running but link token is null
            <Callout warning>
              <div>
                Unable to fetch link_token: please make sure your backend server
                is running and that your .env file has been configured
                correctly.
              </div>
              <div>
                If you are on a Windows machine, please ensure that you have
                cloned the repo with{" "}
                {/* Link to Windows-specific instructions */}
                <InlineLink
                  href="https://github.com/plaid/quickstart#special-instructions-for-windows"
                  target="_blank"
                >
                  symlinks turned on.
                </InlineLink>{" "}
                You can also try checking your{" "}
                {/* Link to Plaid dashboard activity log */}
                <InlineLink
                  href="https://dashboard.plaid.com/activity/logs"
                  target="_blank"
                >
                  activity log
                </InlineLink>{" "}
                on your Plaid dashboard.
              </div>
              {/* Display error details if available */}
              <div>
                Error Code: <code>{linkTokenError.error_code}</code>
              </div>
              <div>
                Error Type: <code>{linkTokenError.error_type}</code>{" "}
              </div>
              <div>Error Message: {linkTokenError.error_message}</div>
            </Callout>
          ) : linkToken === "" ? (
            // Display a loading button if link token is an empty string (indicating it's being fetched)
            <div className={styles.linkButton}>
              <Button large disabled>
                Loading...
              </Button>
            </div>
          ) : (
            // Display the Link component if link token is available
            <div className={styles.linkButton}>
              <Link />
            </div>
          )}
        </>
      ) : (
        // Content to display when Link flow has succeeded
        <>
          {isPaymentInitiation ? (
            // Content specific to payment initiation flow
            <>
              <h4 className={styles.subtitle}>
                Congrats! Your payment is now confirmed.
                <p />
                <Callout>
                  You can see information of all your payments in the{" "}
                  {/* Link to Plaid Payments Dashboard */}
                  <InlineLink
                    href="https://dashboard.plaid.com/activity/payments"
                    target="_blank"
                  >
                    Payments Dashboard
                  </InlineLink>
                  .
                </Callout>
              </h4>
              <p className={styles.requests}>
                Now that the 'payment_id' stored in your server, you can use it
                to access the payment information:
              </p>
            </>
          ) : (
            // Content for non-payment initiation flows
            <>
              {isItemAccess ? (
                // Message displayed when an Item has been successfully created
                <h4 className={styles.subtitle}>
                  Congrats! By linking an account, you have created an{" "}
                  {/* Link to Plaid documentation about Items */}
                  <InlineLink
                    href="http://plaid.com/docs/quickstart/glossary/#item"
                    target="_blank"
                  >
                    Item
                  </InlineLink>
                  .
                </h4>
              ) : userToken ? (
                // Message displayed when data has been successfully linked to a User
                <h4 className={styles.subtitle}>
                  Congrats! You have successfully linked data to a User.
                </h4>
              ) : (
                // Warning displayed if Item creation failed
                <h4 className={styles.subtitle}>
                  <Callout warning>
                    Unable to create an item. Please check your backend server
                  </Callout>
                </h4>
              )}
              {/* Container for displaying Item access information */}
              <div className={styles.itemAccessContainer}>
                {/* Display Item ID if available */}
                {itemId && (
                  <p className={styles.itemAccessRow}>
                    <span className={styles.idName}>item_id</span>
                    <span className={styles.tokenText}>{itemId}</span>
                  </p>
                )}

                {/* Display Access Token if available */}
                {accessToken && (
                  <p className={styles.itemAccessRow}>
                    <span className={styles.idName}>access_token</span>
                    <span className={styles.tokenText}>{accessToken}</span>
                  </p>
                )}

                {/* Display User Token if available */}
                {userToken && (
                  <p className={styles.itemAccessRow}>
                    <span className={styles.idName}>user_token</span>
                    <span className={styles.tokenText}>{userToken}</span>
                  </p>
                )}
              </div>
              {/* Display message about available requests if Item access or User Token is available */}
              {(isItemAccess || userToken) && (
                <p className={styles.requests}>
                  Now that you have {accessToken && "an access_token"}
                  {accessToken && userToken && " and "}
                  {userToken && "a user_token"}, you can make all of the
                  following requests:
                </p>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

// Set display name for the component (useful for debugging)
Header.displayName = "Header";

// Export the Header component as the default export
export default Header;



// import React, { useContext } from "react";
// import Callout from "plaid-threads/Callout";
// import Button from "plaid-threads/Button";
// import InlineLink from "plaid-threads/InlineLink";

// import Link from "../Link";
// import Context from "../../Context";

// import styles from "./index.module.scss";

// const Header = () => {
//   const {
//     itemId,
//     accessToken,
//     userToken,
//     linkToken,
//     linkSuccess,
//     isItemAccess,
//     backend,
//     linkTokenError,
//     isPaymentInitiation,
//   } = useContext(Context);

//   return (
//     <div className={styles.grid}>
//       <h3 className={styles.title}>Plaid Quickstart</h3>

//       {!linkSuccess ? (
//         <>
//           <h4 className={styles.subtitle}>
//             A sample end-to-end integration with Plaid
//           </h4>
//           <p className={styles.introPar}>
//             The Plaid flow begins when your user wants to connect their bank
//             account to your app. Simulate this by clicking the button below to
//             launch Link - the client-side component that your users will
//             interact with in order to link their accounts to Plaid and allow you
//             to access their accounts via the Plaid API.
//           </p>
//           {/* message if backend is not running and there is no link token */}
//           {!backend ? (
//             <Callout warning>
//               Unable to fetch link_token: please make sure your backend server
//               is running and that your .env file has been configured with your
//               <code>PLAID_CLIENT_ID</code> and <code>PLAID_SECRET</code>.
//             </Callout>
//           ) : /* message if backend is running and there is no link token */
//           linkToken == null && backend ? (
//             <Callout warning>
//               <div>
//                 Unable to fetch link_token: please make sure your backend server
//                 is running and that your .env file has been configured
//                 correctly.
//               </div>
//               <div>
//                 If you are on a Windows machine, please ensure that you have
//                 cloned the repo with{" "}
//                 <InlineLink
//                   href="https://github.com/plaid/quickstart#special-instructions-for-windows"
//                   target="_blank"
//                 >
//                   symlinks turned on.
//                 </InlineLink>{" "}
//                 You can also try checking your{" "}
//                 <InlineLink
//                   href="https://dashboard.plaid.com/activity/logs"
//                   target="_blank"
//                 >
//                   activity log
//                 </InlineLink>{" "}
//                 on your Plaid dashboard.
//               </div>
//               <div>
//                 Error Code: <code>{linkTokenError.error_code}</code>
//               </div>
//               <div>
//                 Error Type: <code>{linkTokenError.error_type}</code>{" "}
//               </div>
//               <div>Error Message: {linkTokenError.error_message}</div>
//             </Callout>
//           ) : linkToken === "" ? (
//             <div className={styles.linkButton}>
//               <Button large disabled>
//                 Loading...
//               </Button>
//             </div>
//           ) : (
//             <div className={styles.linkButton}>
//               <Link />
//             </div>
//           )}
//         </>
//       ) : (
//         <>
//           {isPaymentInitiation ? (
//             <>
//               <h4 className={styles.subtitle}>
//                 Congrats! Your payment is now confirmed.
//                 <p />
//                 <Callout>
//                   You can see information of all your payments in the{" "}
//                   <InlineLink
//                     href="https://dashboard.plaid.com/activity/payments"
//                     target="_blank"
//                   >
//                     Payments Dashboard
//                   </InlineLink>
//                   .
//                 </Callout>
//               </h4>
//               <p className={styles.requests}>
//                 Now that the 'payment_id' stored in your server, you can use it
//                 to access the payment information:
//               </p>
//             </>
//           ) : (
//             /* If not using the payment_initiation product, show the item_id and access_token information */ <>
//               {isItemAccess ? (
//                 <h4 className={styles.subtitle}>
//                   Congrats! By linking an account, you have created an{" "}
//                   <InlineLink
//                     href="http://plaid.com/docs/quickstart/glossary/#item"
//                     target="_blank"
//                   >
//                     Item
//                   </InlineLink>
//                   .
//                 </h4>
//               ) : userToken ? (
//                 <h4 className={styles.subtitle}>
//                   Congrats! You have successfully linked data to a User.
//                 </h4>
//               ) : (
//                 <h4 className={styles.subtitle}>
//                   <Callout warning>
//                     Unable to create an item. Please check your backend server
//                   </Callout>
//                 </h4>
//               )}
//               <div className={styles.itemAccessContainer}>
//                 {itemId && (
//                   <p className={styles.itemAccessRow}>
//                     <span className={styles.idName}>item_id</span>
//                     <span className={styles.tokenText}>{itemId}</span>
//                   </p>
//                 )}

//                 {accessToken && (
//                   <p className={styles.itemAccessRow}>
//                     <span className={styles.idName}>access_token</span>
//                     <span className={styles.tokenText}>{accessToken}</span>
//                   </p>
//                 )}

//                 {userToken && (
//                   <p className={styles.itemAccessRow}>
//                     <span className={styles.idName}>user_token</span>
//                     <span className={styles.tokenText}>{userToken}</span>
//                   </p>
//                 )}
//               </div>
//               {(isItemAccess || userToken) && (
//                 <p className={styles.requests}>
//                   Now that you have {accessToken && "an access_token"}
//                   {accessToken && userToken && " and "}
//                   {userToken && "a user_token"}, you can make all of the
//                   following requests:
//                 </p>
//               )}
//             </>
//           )}
//         </>
//       )}
//     </div>
//   );
// };

// Header.displayName = "Header";

// export default Header;
