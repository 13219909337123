// Import necessary types and functions from React
import { createContext, useReducer, Dispatch, ReactNode } from "react";

// Define the shape of the application state
interface QuickstartState {
  linkSuccess: boolean;          // Indicates if the Plaid Link process was successful
  isItemAccess: boolean;         // Indicates if the app has access to Plaid Items
  isPaymentInitiation: boolean;  // Indicates if payment initiation is enabled
  isUserTokenFlow: boolean;      // Indicates if the app is using a user token flow
  isCraProductsExclusively: boolean; // Indicates if only CRA products are being used
  linkToken: string | null;      // The Plaid Link token
  accessToken: string | null;    // The Plaid access token
  userToken: string | null;      // The user token, if applicable
  itemId: string | null;         // The ID of the Plaid Item
  isError: boolean;              // Indicates if there's an error state
  backend: boolean;              // Indicates if the backend is operational
  products: string[];            // Array of Plaid products being used
  linkTokenError: {              // Object to store Link token error details
    error_message: string;
    error_code: string;
    error_type: string;
  };
}

// Define the initial state of the application
const initialState: QuickstartState = {
  linkSuccess: false,
  isItemAccess: true,
  isPaymentInitiation: false,
  isCraProductsExclusively: false,
  isUserTokenFlow: false,
  linkToken: "", // Empty string instead of null to prevent brief error message on load
  userToken: null,
  accessToken: null,
  itemId: null,
  isError: false,
  backend: true,
  products: ["transactions"], // Default to transactions product
  linkTokenError: {
    error_type: "",
    error_code: "",
    error_message: "",
  },
};

// Define the shape of actions that can be dispatched to update the state
type QuickstartAction = {
  type: "SET_STATE";
  state: Partial<QuickstartState>; // Allows updating a subset of the state
};

// Define the shape of the context, which includes the state and a dispatch function
interface QuickstartContext extends QuickstartState {
  dispatch: Dispatch<QuickstartAction>;
}

// Create the context with an initial value
// The 'as QuickstartContext' assertion is used because the initial state doesn't include a dispatch function
const Context = createContext<QuickstartContext>(
  initialState as QuickstartContext
);

// Destructure the Provider component from the context
const { Provider } = Context;

// Define the QuickstartProvider component
export const QuickstartProvider: React.FC<{ children: ReactNode }> = (
  props
) => {
  // Define the reducer function to handle state updates
  const reducer = (
    state: QuickstartState,
    action: QuickstartAction
  ): QuickstartState => {
    switch (action.type) {
      case "SET_STATE":
        // Merge the existing state with the new partial state
        return { ...state, ...action.state };
      default:
        // If the action type is not recognized, return the current state
        return { ...state };
    }
  };

  // Use the useReducer hook to manage state with the defined reducer and initial state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Render the Provider component with the current state and dispatch function as its value
  return <Provider value={{ ...state, dispatch }}>{props.children}</Provider>;
};

// Export the context as the default export
export default Context;