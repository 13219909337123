// Import React library - fundamental for creating React components
import React from "react";

// Import ReactDOM - provides DOM-specific methods for rendering React components
import ReactDOM from "react-dom";

// Import the main App component from the local file
import App from "./App";

// Import the QuickstartProvider component from the Context file
// This provider will wrap the App component to provide state management
import { QuickstartProvider } from "./Context";

// Import the reportWebVitals function for performance measurement
import reportWebVitals from "./reportWebVitals";

// Use ReactDOM.render to render the React application to the DOM
ReactDOM.render(
  // React.StrictMode is a wrapper component that checks for potential problems in the app
  <React.StrictMode>
    // QuickstartProvider wraps the App component to provide context
    <QuickstartProvider>
      // The main App component
      <App />
    </QuickstartProvider>
  </React.StrictMode>,
  // The DOM element where the React app will be rendered
  // This expects an element with id "root" to exist in the HTML
  document.getElementById("root")
);

// This comment explains how to use reportWebVitals for performance measurement
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// Call reportWebVitals without arguments
// This will not log performance metrics by default
// To enable logging, you would pass a callback function here
reportWebVitals();