// Import necessary dependencies from React
import React, { useEffect, useContext, useCallback } from "react";

// Import custom components
import Header from "./Components/Headers";
import Products from "./Components/ProductTypes/Products";
import Items from "./Components/ProductTypes/Items";

// Import the Context for state management
import Context from "./Context";

// Import styles for this component
import styles from "./App.module.scss";

// Import a type from Plaid library
import { CraCheckReportProduct } from "plaid";

// Define the main App component as a functional component
const App = () => {
  // Destructure values and functions from the Context
  const { linkSuccess, isPaymentInitiation, itemId, dispatch } =
    useContext(Context);

  // Define an async function to get info from the backend
  // This is wrapped in useCallback to optimize performance
  const getInfo = useCallback(async () => {
    // Make a POST request to the /api/info endpoint
    const response = await fetch("/api/info", { method: "POST" });
    // If the response is not OK, update the state to indicate backend is down
    if (!response.ok) {
      dispatch({ type: "SET_STATE", state: { backend: false } });
      return { paymentInitiation: false };
    }
    // Parse the JSON response
    const data = await response.json();
    // Check if payment_initiation is included in the products
    const paymentInitiation: boolean =
      data.products.includes("payment_initiation");
    // Get all possible values of CraCheckReportProduct enum
    const craEnumValues = Object.values(CraCheckReportProduct);
    // Check if any product is a CraCheckReportProduct
    const isUserTokenFlow: boolean = data.products.some(
      (product: CraCheckReportProduct) => craEnumValues.includes(product)
    );
    // Check if all products are CraCheckReportProducts
    const isCraProductsExclusively: boolean = data.products.every(
      (product: CraCheckReportProduct) => craEnumValues.includes(product)
    );
    // Dispatch an action to update the state with the new information
    dispatch({
      type: "SET_STATE",
      state: {
        products: data.products,
        isPaymentInitiation: paymentInitiation,
        isCraProductsExclusively: isCraProductsExclusively,
        isUserTokenFlow: isUserTokenFlow,
      },
    });
    // Return an object with paymentInitiation and isUserTokenFlow flags
    return { paymentInitiation, isUserTokenFlow };
  }, [dispatch]); // dispatch is a dependency for this callback

  // Define an async function to generate a user token
  // This is wrapped in useCallback to optimize performance
  const generateUserToken = useCallback(async () => {
    // Make a POST request to the api/create_user_token endpoint
    const response = await fetch("api/create_user_token", { method: "POST" });
    // If the response is not OK, update the state to set userToken to null
    if (!response.ok) {
      dispatch({ type: "SET_STATE", state: { userToken: null } });
      return;
    }
    // Parse the JSON response
    const data = await response.json();
    if (data) {
      // If there's an error in the response, update the state with the error
      if (data.error != null) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: null,
            linkTokenError: data.error,
          },
        });
        return;
      }
      // If successful, update the state with the new user token
      dispatch({ type: "SET_STATE", state: { userToken: data.user_token } });
      return data.user_token;
    }
  }, [dispatch]); // dispatch is a dependency for this callback

  // Define an async function to generate a link token
  // This is wrapped in useCallback to optimize performance
  const generateToken = useCallback(
    async (isPaymentInitiation) => {
      // Choose the appropriate endpoint based on whether it's a payment initiation
      const path = isPaymentInitiation
        ? "/api/create_link_token_for_payment"
        : "/api/create_link_token";
      // Make a POST request to the chosen endpoint
      const response = await fetch(path, {
        method: "POST",
      });
      // If the response is not OK, update the state to set linkToken to null
      if (!response.ok) {
        dispatch({ type: "SET_STATE", state: { linkToken: null } });
        return;
      }
      // Parse the JSON response
      const data = await response.json();
      if (data) {
        // If there's an error in the response, update the state with the error
        if (data.error != null) {
          dispatch({
            type: "SET_STATE",
            state: {
              linkToken: null,
              linkTokenError: data.error,
            },
          });
          return;
        }
        // If successful, update the state with the new link token
        dispatch({ type: "SET_STATE", state: { linkToken: data.link_token } });
      }
      // Save the link_token in localStorage for later use in OAuth flow
      localStorage.setItem("link_token", data.link_token);
    },
    [dispatch] // dispatch is a dependency for this callback
  );

  // Use the useEffect hook to perform side effects when the component mounts
  useEffect(() => {
    // Define an async function to initialize the app
    const init = async () => {
      // Get initial info from the backend
      const { paymentInitiation, isUserTokenFlow } = await getInfo();
      // Check if the current URL includes an OAuth state ID
      if (window.location.href.includes("?oauth_state_id=")) {
        // If it does, retrieve the link token from localStorage and update the state
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: localStorage.getItem("link_token"),
          },
        });
        return;
      }

      // If it's a user token flow, generate a user token
      if (isUserTokenFlow) {
        await generateUserToken();
      }
      // Generate a link token
      generateToken(paymentInitiation);
    };
    // Call the init function
    init();
  }, [dispatch, generateToken, generateUserToken, getInfo]); // These are dependencies for this effect

  // Render the component
  return (
    <div className={styles.App}>
      <div className={styles.container}>
        <Header />
        {linkSuccess && (
          <>
            <Products />
            {!isPaymentInitiation && itemId && <Items />}
          </>
        )}
      </div>
    </div>
  );
};

// Export the App component as the default export
export default App;