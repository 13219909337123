// Import React library
import React from "react";

// Import custom types from dataUtilities
import { DataItem, Categories } from "../../dataUtilities";

// Import styles specific to this component
import styles from "./Identity.module.scss";

// Define the props interface for the Identity component
interface Props {
  data: Array<DataItem>;      // Array of data items to display in the identity table
  categories: Array<Categories>; // Array of category objects defining table structure
}

// Define the Identity functional component
const Identity = (props: Props) => {
  // Create header elements based on category titles
  const identityHeaders = props.categories.map((category, index) => (
    <span key={index} className={styles.identityHeader}>
      {category.title}
    </span>
  ));

  // Create row elements based on data and categories
  const identityRows = props.data.map((item: DataItem | any, index) => (
    <div key={index} className={styles.identityDataRow}>
      {props.categories.map((category: Categories, index) => (
        <span key={index} className={styles.identityDataField}>
          {item[category.field]}
        </span>
      ))}
    </div>
  ));

  // Render the identity table structure
  return (
    <div className={styles.identityTable}>
      {/* Render the headers row */}
      <div className={styles.identityHeadersRow}>{identityHeaders}</div>
      {/* Render the data rows */}
      <div className={styles.identityDataBody}>{identityRows}</div>
    </div>
  );
};

// Set the display name for the component (useful for debugging)
Identity.displayName = "Identity";

// Export the Identity component as the default export
export default Identity;

// import React from "react";

// import { DataItem, Categories } from "../../dataUtilities";

// import styles from "./Identity.module.scss";

// interface Props {
//   data: Array<DataItem>;
//   categories: Array<Categories>;
// }

// const Identity = (props: Props) => {
//   const identityHeaders = props.categories.map((category, index) => (
//     <span key={index} className={styles.identityHeader}>
//       {category.title}
//     </span>
//   ));

//   const identityRows = props.data.map((item: DataItem | any, index) => (
//     <div key={index} className={styles.identityDataRow}>
//       {props.categories.map((category: Categories, index) => (
//         <span key={index} className={styles.identityDataField}>
//           {item[category.field]}
//         </span>
//       ))}
//     </div>
//   ));

//   return (
//     <div className={styles.identityTable}>
//       <div className={styles.identityHeadersRow}>{identityHeaders}</div>
//       <div className={styles.identityDataBody}>{identityRows}</div>
//     </div>
//   );
// };

// Identity.displayName = "Identity";

// export default Identity;
