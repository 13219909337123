// Import React and useState hook for component creation and state management
import React, { useState } from "react";
// Import Button and Note components from plaid-threads library
import Button from "plaid-threads/Button";
import Note from "plaid-threads/Note";

// Import custom components and types
import Table from "../Table";
import Error from "../Error";
import { DataItem, Categories, ErrorDataItem, Data } from "../../dataUtilities";

// Import styles for this component
import styles from "./index.module.scss";

// Define the props interface for the Endpoint component
interface Props {
  endpoint: string;        // The API endpoint to call
  name?: string;           // Optional name of the endpoint
  categories: Array<Categories>; // Categories for data display
  schema: string;          // Schema information
  description: string;     // Description of the endpoint
  transformData: (arg: any) => Array<DataItem>; // Function to transform API data
}

// Define the Endpoint component
const Endpoint = (props: Props) => {
  // State variables using useState hook
  const [showTable, setShowTable] = useState(false); // Controls table visibility
  const [transformedData, setTransformedData] = useState<Data>([]); // Holds transformed API data
  const [pdf, setPdf] = useState<string | null>(null); // Holds PDF data if available
  const [error, setError] = useState<ErrorDataItem | null>(null); // Holds error information
  const [isLoading, setIsLoading] = useState(false); // Indicates if data is being fetched

  // Function to fetch data from the API
const getData = async () => {
  // Set loading state to true to show a loading indicator
  setIsLoading(true);

  // Construct the API URL using the endpoint prop
  // Make a GET request to the constructed URL
  const response = await fetch(`/api/${props.endpoint}`, { method: "GET" });

  // Parse the JSON response
  const data = await response.json();

  // Check if the response contains an error
  if (data.error != null) {
    // If there's an error, update the error state
    setError(data.error);
    // Set loading state to false
    setIsLoading(false);
    // Exit the function early
    return;
  }

  // If no error, transform the data using the provided transform function
  // and update the transformedData state
  setTransformedData(props.transformData(data));

  // Check if the response includes PDF data
  if (data.pdf != null) {
    // If PDF data is present, update the pdf state
    setPdf(data.pdf);
  }

  // Set showTable to true to display the data table
  setShowTable(true);

  // Set loading state to false as the operation is complete
  setIsLoading(false);
};

// Function to determine the PDF file name based on the endpoint name
const getPdfName = () => {
  // Use a switch statement to check the value of props.name
  switch(props.name) {
    // If the name is 'Assets', return "Asset Report.pdf"
    case 'Assets':
      return "Asset Report.pdf";
    // If the name is "CRA Base Report", return "Plaid Check Report.pdf"
    case "CRA Base Report":
      return "Plaid Check Report.pdf";
    // If the name is "CRA Income Insights", return "Plaid Check Report with Insights.pdf"
    case "CRA Income Insights":
      return "Plaid Check Report with Insights.pdf";
    // For any other name (or if name is undefined), return "Statement.pdf"
    default: 
      return "Statement.pdf";
  }
};


// Render the component
return (
  // React Fragment to group multiple elements without adding extra nodes to the DOM
  <>
    {/* Main container for the endpoint information */}
    <div className={styles.endpointContainer}>
      {/* Display a "POST" note */}
      <Note info className={styles.post}>
        POST
      </Note>
      {/* Container for the endpoint details */}
      <div className={styles.endpointContents}>
        {/* Header section for the endpoint */}
        <div className={styles.endpointHeader}>
          {/* Conditionally render the endpoint name if it exists */}
          {props.name != null && (
            <span className={styles.endpointName}>{props.name}</span>
          )}
          {/* Display the schema information */}
          <span className={styles.schema}>{props.schema}</span>
        </div>
        {/* Display the endpoint description */}
        <div className={styles.endpointDescription}>{props.description}</div>
      </div>
      {/* Container for action buttons */}
      <div className={styles.buttonsContainer}>
        {/* Button to trigger the getData function */}
        <Button
          small
          centered
          wide
          secondary
          className={styles.sendRequest}
          onClick={getData}
        >
          {/* Display "Loading..." when isLoading is true, otherwise show "Send request" */}
          {isLoading ? "Loading..." : `Send request`}
        </Button>
        {/* Conditionally render the PDF download button if pdf data exists */}
        {pdf != null && (
          <Button
            small
            centered
            wide
            className={styles.pdf}
            // Set the href to a data URL for the PDF
            href={`data:application/pdf;base64,${pdf}`}
            // Set the download attribute to the PDF name from getPdfName()
            componentProps={{ download: getPdfName()}}
          >
            Download PDF
          </Button>
        )}
      </div>
    </div>
    {/* Conditionally render the Table component if showTable is true */}
    {showTable && (
      <Table
        categories={props.categories}
        data={transformedData}
        // Set isIdentity prop based on whether the endpoint is "identity"
        isIdentity={props.endpoint === "identity"}
      />
    )}
    {/* Conditionally render the Error component if an error exists */}
    {error != null && <Error error={error} />}
  </>
);
};

//   // Render the component {{original unbroken}}
//   return (
//     <>
//       <div className={styles.endpointContainer}>
//         <Note info className={styles.post}>
//           POST
//         </Note>
//         <div className={styles.endpointContents}>
//           <div className={styles.endpointHeader}>
//             {props.name != null && (
//               <span className={styles.endpointName}>{props.name}</span>
//             )}
//             <span className={styles.schema}>{props.schema}</span>
//           </div>
//           <div className={styles.endpointDescription}>{props.description}</div>
//         </div>
//         <div className={styles.buttonsContainer}>
//           <Button
//             small
//             centered
//             wide
//             secondary
//             className={styles.sendRequest}
//             onClick={getData}
//           >
//             {isLoading ? "Loading..." : `Send request`}
//           </Button>
//           {pdf != null && (
//             <Button
//               small
//               centered
//               wide
//               className={styles.pdf}
//               href={`data:application/pdf;base64,${pdf}`}
//               componentProps={{ download: getPdfName()}}
//             >
//               Download PDF
//             </Button>
//           )}
//         </div>
//       </div>
//       {showTable && (
//         <Table
//           categories={props.categories}
//           data={transformedData}
//           isIdentity={props.endpoint === "identity"}
//         />
//       )}
//       {error != null && <Error error={error} />}
//     </>
//   );
// };

// Set the display name for the component (useful for debugging)
Endpoint.displayName = "Endpoint";

// Export the Endpoint component as the default export
export default Endpoint;